import React, { useState } from 'react';
import { db } from '../firebase/firebaseConfig';
import { utils, writeFile } from 'xlsx';
import { getDocs, collection } from 'firebase/firestore';

const MassDownload = () => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchDataAndExport = async () => {
    setIsLoading(true);
    const querySnapshot = await getDocs(collection(db, "cyl_info"));
    const data = querySnapshot.docs.map(doc => ({
      docId: doc.id,
      productCode: doc.data().productCode,
      productDescription: doc.data().productDescription,
    }));

    // Convert data to worksheet using utils
    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Products");

    // Create XLSX file and download using writeFile
    writeFile(wb, "cyl_info.xlsx");
    setIsLoading(false);
  };

  return (
    <div>
      <button onClick={fetchDataAndExport} disabled={isLoading}>
        {isLoading ? 'Exporting...' : 'Export to Excel'}
      </button>
    </div>
  );
};

export default MassDownload;
