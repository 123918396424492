import React, { useState } from 'react';
import { db } from '../firebase/firebaseConfig';
import { collection, getDocs, doc, writeBatch } from 'firebase/firestore';

const DuplicateFirestoreCollection = () => {
  const [status, setStatus] = useState('');

  const duplicateCollection = async () => {
    try {
      const sourceCollection = collection(db, 'cylinders_warehouse');
      const sourceSnapshot = await getDocs(sourceCollection);

      if (sourceSnapshot.empty) {
        setStatus('Source collection is empty or does not exist.');
        return;
      }

      let batch = writeBatch(db);
      let batchCounter = 0;
      let batchNumber = 1;

      sourceSnapshot.forEach((docSnapshot) => {
        const data = docSnapshot.data();
        const newDocRef = doc(db, `cylinders_warehouse_duplicate/${docSnapshot.id}`);
        batch.set(newDocRef, data);
        batchCounter++;

        // Commit batch every 500 writes
        if (batchCounter === 500) {
          batch.commit();
          batch = writeBatch(db);
          batchCounter = 0;
          batchNumber++;
        }
      });

      // Commit the final batch
      if (batchCounter > 0) {
        await batch.commit();
      }

      setStatus(`Collection duplicated successfully across ${batchNumber} batch(es).`);
    } catch (error) {
      console.error('Error duplicating collection:', error);
      setStatus('Error duplicating collection. Check console for details.');
    }
  };

  return (
    <div>
      <h2>Duplicate Firestore Collection</h2>
      <button onClick={duplicateCollection} style={{ padding: '10px 20px', marginBottom: '10px' }}>
        Duplicate Cylinders Warehouse Collection
      </button>
      <p>{status}</p>
    </div>
  );
};

export default DuplicateFirestoreCollection;
